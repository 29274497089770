export const numerals = [
  "I",
  "II",
  "III",
  "IV",
  "V",
  "VI",
  "VII",
  "VIII",
  "IX",
  "X",
  "X",
  "XII",
  "XIII",
];

export function Button(props) {
  return (
    <div className="button-container" {...props}>
      <div className={`button ${props.color}`}>
        <button aria-label={props.text}>{props.text}</button>
        <span className="button--overlay"></span>
      </div>
    </div>
  );
}

export function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export function describeScore(articles) {
  const score = articles.reduce((acc, val) => {
    return val.correct ? acc + 1 : acc;
  }, 0);
  return (
    <div>
      <span className="volume-score">
        {score} / {articles.length}
      </span>
      <br></br>
    </div>
  );
}

export function describeNextVol(nextVol, nextVol_idx) {
  return (
    <div className="nextVol-description-container">
      <div className="nextVol-description">
        <span>Volume {numerals[nextVol_idx]}</span>
        <br></br>
        <span>{nextVol.title}</span>
        <br></br>
        <span>{nextVol.articles.length} articles</span>
      </div>
    </div>
  );
}
