import Guffaw from "./Guffaw.js";
import Starter from "./Starter";
import { volumes } from "./data";
import React, { useState } from "react";
import ReactGA from "react-ga";

function App() {
  const [state, setState] = useState({
    in_game: false,
    activeVol: 0,
    completed_vols: [],
  });

  function startGame(vol) {
    setState({ ...state, in_game: true, activeVol: vol });
  }

  function returnToMenu() {
    setState({ ...state, in_game: false, activeVol: state.activeVol + 1 });
  }

  function moveToNextVol() {
    const nextVol = state.activeVol + 1;
    if (nextVol + 1 >= volumes.length) {
      returnToMenu();
      return;
    }
    setState({ ...state, in_game: true, activeVol: nextVol });
  }

  return (
    <div style={{ height: "100vh", width: "auto", backgroundColor: "#dfdedb" }}>
      {state.in_game && (
        <div>
          <button
            onClick={() => {
              returnToMenu(state.activeVol);
            }}
            className="return-to-menu-button"
          >
            MENU
          </button>
          <a
            className="contribute-button"
            href="mailto:skablam.journal@gmail.com?subject=I%20had%20a%20good%20idea"
          >
            <button>CONTRIBUTE</button>
          </a>
        </div>
      )}
      {state.in_game ? (
        <Guffaw
          returnToMenu={returnToMenu}
          volume={volumes[state.activeVol]}
          returnToMenu={returnToMenu}
          moveToNextVol={moveToNextVol}
          nextVol={
            volumes[state.activeVol + 1].articles.length > 0 &&
            volumes[state.activeVol + 1]
          }
          nextVol_idx={state.activeVol + 1}
        />
      ) : (
        <Starter
          startGame={startGame}
          volumes={volumes}
          activeVol={state.activeVol}
        />
      )}
    </div>
  );
}

export default App;
