import React, { useReducer } from "react";
import { Button, describeScore, describeNextVol } from "./Utils";
import gradpic from "./images/graduate-export.png";
import beerpic from "./images/beer.png";
import LinkIcon from '@mui/icons-material/Link';

const base_state = {
  activeArt: 0,
  revealed: false,
  correct: null,
  finished: false,
  mut_articles: [],
};

function reducer(state, action) {
  switch (action.type) {
    case "answer":
      return {
        ...state,
        correct: action.correct,
        revealed: true,
        mut_articles: [
          ...state.mut_articles,
          { ...action.article, correct: action.correct },
        ],
      };
    case "next":
      const finished = state.mut_articles.length >= action.vol_length;
      return {
        ...state,
        activeArt: state.activeArt + 1,
        revealed: false,
        finished,
      };

    case "reset":
      return { ...base_state };

    default:
      console.error("super unexpected!");
  }
}

export default function Guffaw({
  returnToMenu,
  volume,
  moveToNextVol,
  nextVol,
  nextVol_idx,
}) {
  const [state, dispatch] = useReducer(reducer, { ...base_state });

  function getCurrent() {
    return volume.articles[state.activeArt];
  }

  function answer(verdict = Boolean) {
    const current = getCurrent();
    const isTrue = current.authentique;
    const correct = (isTrue && verdict) || (!isTrue && !verdict);
    dispatch({ type: "answer", correct, article: current });
  }

  function next() {
    dispatch({ type: "next", vol_length: volume.articles.length });
  }

  function moveNextVol() {
    dispatch({ type: "reset" });
    moveToNextVol();
  }

  function Article({ revealed, current, answer, next }) {
    if (!current) return null;
    const show_grey_true = revealed && !current.authentique;
    const show_grey_false = revealed && current.authentique;

    return (
      <div style={{ width: "100%" }}>
        <p className="article-title"> {current.title} </p>
        <hr className="article-hr"></hr>
        <br></br>
        <div className="option-button-container">
          <div className="article-verdict-button">
            <Button
              text="REAL"
              color={show_grey_true ? "" : "green"}
              onClick={() => {
                if (revealed) return;
                answer(true);
              }}
            />
          </div>
          <div className="article-verdict-button">
            <Button
              text="FAKE"
              color={show_grey_false ? "" : "red"}
              onClick={() => {
                if (revealed) return;
                answer(false);
              }}
            />
          </div>
        </div>

        {revealed && (
          <div className="article-answer-container">
            <a 
              target='_blank' 
              href={current.link}
              style={{ textDecoration: 'none', color: 'inherit' }} // Prevent default anchor tag styling
            >
              {current.link ? (
                <LinkIcon style={{ color: '#4A90E2' }} />
              ) : (
                <LinkIcon style={{ visibility: 'hidden' }} />
              )}
              <p>{state.correct ? "correct" : "incorrect"} !</p>
            </a>
            <br></br>
            <button
              onClick={() => {
                next();
              }}
            >
              NEXT
            </button>
          
          </div>
        )}
      </div>
    );
  }

  function EndGameComps() {
    return (
      <div>
        {describeScore(state.mut_articles)}
        <br></br>
        <img className="graduate-image" src={gradpic}></img>
        <hr></hr>
        {nextVol ? (
          <div>
            {describeNextVol(nextVol, nextVol_idx)}
            <div>
              <Button
                text="CONTINUE"
                color="green"
                onClick={() => {
                  moveNextVol();
                }}
              ></Button>
            </div>
          </div>
        ) : (
          <Button
            text="MENU"
            onClick={() => {
              returnToMenu();
            }}
          ></Button>
        )}
      </div>
    );
  }

  return (
    <div className="article-container">
      {state.finished || (
        <p className="article-number">#{state.activeArt + 1}</p>
      )}
      <div className="article-body">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {state.finished ? (
            <EndGameComps />
          ) : (
            <Article
              revealed={state.revealed}
              next={next}
              answer={answer}
              current={getCurrent()}
            />
          )}
        </div>
      </div>
    </div>
  );
}
