import React, { useState, useReducer } from "react";
import test from "./images/journal-book.png";
import { numerals, Button } from "./Utils";

function ourReducer(state, action) {
  switch (action.type) {
    case "updateVol":
      return {
        ...state,
        activeVol: action.idx,
      };

    default:
      console.error("unexpected!");
  }
}

export default function Starter({ volumes, startGame, activeVol = 0 }) {
  const [ourState, dispatch] = useReducer(ourReducer, { activeVol });

  function updateVol(idx) {
    dispatch({ type: "updateVol", idx });
  }

  const canStart = volumes[ourState.activeVol].articles.length > 1;

  return (
    <div className="intro-box">
      <h1 className="intro-title">THE JOURNAL</h1>
      <p className="intro-subtitle">A pretentious drinking game.</p>
      <img src={test} className="intro-book" />
      <p className="intro-description">Are these academic articles real?</p>
      <br></br>
      <div className="volume-selector">
        {volumes.map((_vol, i) => {
          const active = ourState.activeVol === i;
          return (
            <div className="volume-selector--item" key={i}>
              <span
                className={`volume-selector--item-inner ${active && "active"}`}
                onClick={() => {
                  updateVol(i);
                }}
              >
                Vol.{numerals[i]}
              </span>
            </div>
          );
        })}
      </div>

      <br></br>

      <div className="volume-selector--breakdown">
        <span>Volume {numerals[ourState.activeVol]}</span>
        <br></br>
        <span>{volumes[ourState.activeVol].title}</span>
        <br></br>
        <span>{volumes[ourState.activeVol].articles.length} articles</span>
        <br></br>
      </div>

      <Button
        text="BEGIN"
        color={canStart ? `green` : ""}
        onClick={() => {
          if (canStart) {
            startGame(ourState.activeVol);
          }
        }}
      />
    </div>
  );
}
