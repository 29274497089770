import { shuffle } from "./Utils";

const vol_i = {
  title: "A Gentle Introduction",
  articles: [
    {
      authentique: true,
      title:
        "When the Left Leg Shakes: Some Observations about Contemporary Arabic Anti-Masturbation Literature.",
      link: "https://www.jstor.org/stable/24754821",
      tags: "ridiculous",
    },
    {
      authentique: false,
      title: "Which genocide matters the least?",
      tags: "outrageous",
    },
    {
      authentique: true,
      title:
        '"Spread your ass cheeks": And other things that should not be said in indigenous languages',
      link: "https://anthrosource.onlinelibrary.wiley.com/doi/abs/10.1111/j.1548-1425.2008.00004.x",
      tags: "outrageous; crude",
    },
    {
      authentique: true,
      title:
        "When the wheels on the bus stop going round and round: occupational injuries, illnesses, and fatalities in public transportation",
      link: "https://www.bls.gov/opub/mlr/2015/article/when-the-wheels-on-the-bus-stop-going-round-and-round.htm",
      tags: "pun",
    },
    {
      authentique: false,
      title:
        "Steal from the rich and give to the Moor: How Islam stole dance music post-1967",
      tags: "ridiculous",
    },
    {
      authentique: true,
      title: "Blind Children Need Training, Not Sympathy ",
      link: "https://journals.sagepub.com/doi/abs/10.2307/3394049?journalCode=mejc",
      tags: "outrageous",
    },
    {
      authentique: true,
      title:
        '"No Sex, please, We\'re Narnians": Turkish Delight, Twelfth Night, and the Problem of Susan',
      link: "https://dc.swosu.edu/mythlore/vol39/iss1/3/",
      tags: "ridiculous",
    },
    {
      authentique: true,
      title: "Could There be a Silver Lining to Zika?",
      link: "https://www.jstor.org/stable/26370475",
      tags: "outrageous",
    },
    {
      authentique: false,
      title: "Are Saudi Arabia’s Roads Safer Without Women? Should They Be?",
      tags: "outrageous",
    },
    {
      authentique: false,
      title:
        "An Enquiry into Paedophilia in the Synagogue: Going Down the Rabbi-hole",
      tags: "outrageous; pun",
    },
    {
      authentique: true,
      title: "The lengths birds will go to avoid incest",
      link: "https://besjournals.onlinelibrary.wiley.com/doi/10.1111/j.1365-2656.2012.02008.x",
      tags: "pun",
    },
    {
      authentique: true,
      title:
        "“Go shag a horse!”: The 17th–18th Century Basque-Icelandic Glossaries Revisited",
      link: "https://www.jstor.org/stable/26663856",
      tags: "outrageous; bizarre; crude",
    },
    {
      authentique: true,
      title:
        "Are full or empty beer bottles sturdier and does their fracture-threshold suffice to break the human skull?",
      link: "https://pubmed.ncbi.nlm.nih.gov/19239964/",
      tags: "bizarre",
    },
    {
      authentique: false,
      title:
        "Rethinking portrayals of the paraplegic in renaissance self-portrait",
      tags: "ridiculous",
    },
    {
      authentique: true,
      title: "Has he called you frigid lately?",
      link: "https://www.jstor.org/stable/26565900",
      tags: "bizarre",
    },
    {
      authentique: false,
      title:
        "\"Don't call me weird, but I'm in favour of normalising upskirting\"",
      tags: "ridiculous",
    },
    {
      authentique: true,
      title: "Is Prose Poetry a Conspiracy Against the Noble Qur’an?",
      link: "https://www.jstor.org/stable/26747451",
      tags: "bizarre",
    },
    {
      authentique: false,
      title: "How do we know that wasps sting?",
      tags: "ridiculous",
    },
    {
      authentique: true,
      title:
        "'I Wish All the Ladies Were Holes in the Road’: The US Air Force Academy and the Gendered Continuum of Violence.",
      link: "https://www.jstor.org/stable/10.1086/680328?searchText=%27I+Wish+All+the+Ladies+Were+Holes+in+the+Road%27+The+US+Air+Force+Academy+and+the+Gendered+Continuum+of+Violence.&searchUri=%2Faction%2FdoBasicSearch%3FQuery%3D%2527I%2BWish%2BAll%2Bthe%2BLadies%2BWere%2BHoles%2Bin%2Bthe%2BRoad%25E2%2580%2599%253A%2BThe%2BUS%2BAir%2BForce%2BAcademy%2Band%2Bthe%2BGendered%2BContinuum%2Bof%2BViolence.&ab_segments=0%2FSYC-7052%2Ftest&refreqid=fastly-default%3Aa62fea5d326aac2b022d2e2207c75c94",
      tags: "bizarre; crude",
    },
    {
      authentique: false,
      title: "Anarcho-constructivism on the building site",
      tags: "ridiculous",
    },
    {
      authentique: false,
      title: "An analysis of the running speed of women in the third trimester",
      tags: "ridiculous",
    },
    {
      authentique: true,
      title: "Children Crying at Birthday Parties: Why?",
      link: "https://www.jstor.org/stable/27822533",
      tags: "ridiculous",
    },
    {
      authentique: false,
      title: "How long can a catheter stay in a man?",
      tags: "ridiculous",
    },
    {
      authentique: true,
      title:
        "'You cannot shake that shimmie here': producing mobility on the dance floor ",
      link: "https://www.jstor.org/stable/44251074",
      tags: "ridiculous",
    },
    {
      authentique: true,
      title:
        "My Job is To Be a Bad Bitch: Locating Women of Color in Postfeminist Media Culture on Love and Hip-Hop: Atlanta",
      link: "https://www.jstor.org/stable/26529209?searchText=My+Job+is+To+Be+a+Bad+Bitch+Locating+Women+of+Color+in+Postfeminist+Media+Culture+on+Love+and+Hip-Hop+Atlanta&searchUri=%2Faction%2FdoBasicSearch%3FQuery%3DMy%2BJob%2Bis%2BTo%2BBe%2Ba%2BBad%2BBitch%2BLocating%2BWomen%2Bof%2BColor%2Bin%2BPostfeminist%2BMedia%2BCulture%2Bon%2BLove%2Band%2BHip-Hop%253A%2BAtlanta&ab_segments=0%2FSYC-7052%2Ftest&refreqid=fastly-default%3Ac9ba1431d59206cd5f3ab55ce4262415",
      tags: "outrageous",
    },
  ],
};

const vol_ii = {
  title: "Vaseline, Prosthetic Limbs and the Organ Grinder",
  articles: [
    {
      authentique: false,
      title: "Rural Tibet - Should we make it our problem?",
      tags: "ridiculous; bizarre",
    },
    {
      authentique: false,
      title:
        "'One Last Bonk!': Patterns in the Terminal Reproductive Effort of the Idolomantis Diabolica",
      tags: "crude;bizarre",
    },
    {
      authentique: false,
      title: "How do we get rid of orphans?",
      tags: "outrageous",
    },
    {
      authentique: false,
      title:
        "Is the state of drainpipage in the Mid-Western Suburban Household a cause for celebration? A critical reading of Chuckett's 'Water Flow and Fluoride Levels in Wilmar, MN'",
    },
    {
      authentique: false,
      title: "Making waves with new prosthetic limbs",
      tags: "bizarre",
    },
    {
      authentique: false,
      title: "Moving towards a sustainable enema",
      tags: "ridiculous",
    },
    {
      authentique: false,
      title: "Selected Letters from My Mother",
      tags: "bizarre",
    },
    {
      authentique: false,
      title: "Virtuous Fondling and Excessive Dogging",
      tags: "bizarre; crude",
    },
    {
      authentique: false,
      title: "The Levant as a Backdoor",
      tags: "ridiculous",
    },
    {
      authentique: false,
      title: "Existential Dread in Farming",
      tags: "ridiculous",
    },
    {
      authentique: false,
      title:
        '"Go to the ant, you sluggard; see its ways and grow wise": An Experimental Test of Morality Recognition in Harvester Ants ',
      tags: "bizarre",
    },
    {
      authentique: true,
      title: "‘My more than sister’: re-examining paradigms of sibling incest",
      link: "https://www.jstor.org/stable/j.ctt2204rt6.6",
      tags: "ridiculous",
    },
    {
      authentique: true,
      title: "Drought drives pigeons to drink ",
      link: "https://www.jstor.org/stable/23470543?searchText=drought+drives+pigeons+to+drink&searchUri=%2Faction%2FdoBasicSearch%3FQuery%3Ddrought%2Bdrives%2Bpigeons%2Bto%2Bdrink&ab_segments=0%2FSYC-7052%2Ftest&refreqid=fastly-default%3Ab686f1e6e7e9977508d20ae4283d05a0",
      tags: "ridiculous",
    },
    {
      authentique: true,
      title:
        "Ashes to Ashes: Thermal Contact Burns in Children Caused by Recreational Fires.",
      link: "https://www.sciencedirect.com/science/article/abs/pii/S030541790800154X?via%3Dihub",
      tags: "pun",
    },
    {
      authentique: true,
      title: "Big Gay Church VARchive: Decade One",
      link: "https://www.jstor.org/stable/10.5406/visuartsrese.46.1.0044",
      tags: "bizarre",
    },
    {
      authentique: true,
      title: "Johnny's Not Jumping: Can We Help Obese Children? ",
      link: "https://www.jstor.org/stable/42726040",
      tags: "outrageous",
    },
    {
      authentique: true,
      title: "There Are Plenty of Atheists in Foxholes — in Sweden ",
      link: "https://doi.org/10.1163/15736121-12341285",
      tags: "bizarre",
    },
    {
      authentique: true,
      title:
        "Penetrating Boundaries: An Ethics of Anti-Perfectionism in Victorian Pornography ",
      link: "https://www.jstor.org/stable/10.2979/victorianstudies.57.3.423",
      tags: "pun; bizarre",
    },
    {
      authentique: true,
      title:
        "Phenomenology and Life: Hegel's inverted world, Cleopatra, and the logic of the crocodile",
      link: "https://www.jstor.org/stable/23267672",
      tags: "bizarre",
    },
    {
      authentique: true,
      title:
        "Seaweed intake and urinary sex hormone levels in preschool Japanese children",
      link: "https://www.jstor.org/stable/41410308?searchText=Seaweed+intake+and+urinary+sex+hormone+levels+in+preschool+Japanese+children&searchUri=%2Faction%2FdoBasicSearch%3FQuery%3DSeaweed%2Bintake%2Band%2Burinary%2Bsex%2Bhormone%2Blevels%2Bin%2Bpreschool%2BJapanese%2Bchildren&ab_segments=0%2FSYC-7052%2Ftest&refreqid=fastly-default%3A105190cc540449ea2ee97cf10c6c157e",
      tags: "bizarre; ridiculous",
    },
    {
      authentique: true,
      title: "Tasting Braille ",
      link: "https://www.jstor.org/stable/44017244",
      tags: "outrageous",
    },
    {
      authentique: true,
      title: "When Not to Recite the Qur'an",
      link: "https://www.jstor.org/stable/20790226",
      tags: "outrageous",
    },
    {
      authentique: true,
      title:
        "Instantly Converting Atrial Fibrillation into Sinus Rhythm by a Digital Rectal Exam on a 29-year-Old Male",
      link: "https://pubmed.ncbi.nlm.nih.gov/21769254/",
      tags: "outrageous; bizarre",
    },
    {
      authentique: true,
      title: "A Suitcase Full of Vaseline, or Travels in the 1970s Gay World",
      link: "https://www.jstor.org/stable/24616541?searchText=A+Suitcase+Full+of+Vaseline%2C+or+Travels+in+the+1970s+Gay+World&searchUri=%2Faction%2FdoBasicSearch%3FQuery%3DA%2BSuitcase%2BFull%2Bof%2BVaseline%252C%2Bor%2BTravels%2Bin%2Bthe%2B1970s%2BGay%2BWorld&ab_segments=0%2FSYC-7052%2Ftest&refreqid=fastly-default%3A1f168bef54e16ac144584142827a6140",
      tags: "outrageous",
    },
    {
      authentique: false,
      title: "Are the Better Educated Less Likely to Eat Spaghetti?",
      tags: "ridiculous",
    },
  ],
};

const vol_iii = {
  title: "Bonk!",
  articles: [
    {
      authentique: false,
      title: "“Too Close for Comfort” – Incest among Finno-Ugric Sami People",
      tags: "pun",
    },
    {
      authentique: false,
      title: "British Soft Power and Chinese Soft Porn",
      tags: "crude; ridiculous",
    },
    {
      authentique: false,
      title: "How does a toucan defend themselves?",
      tags: "ridiculous",
    },
    {
      authentique: false,
      title: "Is patriotism the new nationalism? No, shut up!",
      tags: "ridiculous",
    },
    {
      authentique: false,
      title: "Lampshade Anarchism",
      tags: "bizarre",
    },
    {
      authentique: false,
      title: "Mummy’s Boys: A Discussion on Misgendered Libyan Mummies",
      tags: "pun",
    },
    {
      authentique: false,
      title: "The Elephant in the Room: Ganesh and Racism",
      tags: "pun",
    },
    {
      authentique: false,
      title: "Will the Messiah help stem the rise in childhood obesity? ",
      tags: "ridiculous",
    },
    {
      authentique: false,
      title: "Some Problems Arising From Labelling an Elephant Sexy",
      tags: "crude; outrageous",
    },
    {
      authentique: false,
      title: "Stumbling Toward a Democratic Theory of Masturbation",
      tags: "ridiculous",
    },
    {
      authentique: false,
      title:
        "The Tortoise and the Hair: The silent and inexorable rise of discretionary baldness",
      tags: "pun",
    },
    {
      authentique: false,
      title:
        "The Israeli Shekel and The Hornéd One: Forged in the Fire of Yom Kippur",
      tags: "bizarre",
    },
    {
      authentique: false,
      title:
        "We Need to Talk About Bevan: The British National Health Service Through the Stethoscope",
      tags: "ridiculous",
    },
    {
      authentique: true,
      title: "'Tastes Like Horse Piss': Asian Encounters with European Beer",
      link: "https://www.jstor.org/stable/26362318",
      tags: "crude",
    },
    {
      authentique: true,
      title:
        '"I Hope I Haven\'t Made Another Lampshade": Stevens and John L. Sweeney ',
      link: "https://www.jstor.org/stable/44885353",
      tags: "bizarre",
    },
    {
      authentique: true,
      title: "Academic inbreeding in the Portuguese academia",
      link: "https://www.jstor.org/stable/43648841",
      tags: "ridiculous",
    },
    {
      authentique: true,
      title:
        "Are Commuters in the EU Better Educated than Non-commuters but Worse than Migrants?",
      link: "https://www.jstor.org/stable/26145735",
      tags: "outrageous",
    },
    {
      authentique: true,
      title: "Feasting on Donkey Skin",
      link: "https://www.jstor.org/stable/j.ctv1rmjm7.22",
      tags: "crude",
    },
    {
      authentique: true,
      title: "For Oompa-Loompas, Orange Was The New Black",
      link: "https://www.jstor.org/stable/26362342",
      tags: "ridiculous",
    },
    {
      authentique: true,
      title: "Fuck Nuance",
      link: "https://www.jstor.org/stable/26382881",
      tags: "crude",
    },
    {
      authentique: true,
      title:
        "More than just kissing: cousins and the changing status of family",
      link: "https://www.jstor.org/stable/j.ctt2204rt6.8",
      tags: "crude; outrageous",
    },
    {
      authentique: true,
      title:
        "Not just window dressing: High-performance curtains, blinds and shutters",
      link: "https://www.jstor.org/stable/90009779",
      tags: "pun",
    },
    {
      authentique: true,
      title: 'Pigeons can discriminate "good" and "bad" paintings by children.',
      link: "https://pubmed.ncbi.nlm.nih.gov/19533184/",
      tags: "ridiculous",
    },
    {
      authentique: true,
      title:
        "Sites for Sore Eyes: People with disabilities want the Internet to be more accessible ",
      link: "https://www.jstor.org/stable/24806013",
      tags: "pun",
    },
    {
      authentique: true,
      title: "Why Does Aphrodite Have Her Foot on that Turtle?",
      link: "https://www.jstor.org/stable/arion.27.3.0025",
      tags: "bizarre",
    },
    {
      authentique: true,
      title: "Funny Bird Sex",
      link: "https://www.jstor.org/stable/10.7723/antiochreview.74.1.0008",
      tags: "crude",
    },
    {
      authentique: false,
      title:
        "The morality of pre-natal pornographic immersion as a social contraceptive",
      tags: "outrageous; bizarre",
      notes: "x",
    },
  ],
};

const vol_iv = {
  title: "Semi-Antism",
  articles: [
    {
      authentique: false,
      title: "European Shag Variation in Central Estonia",
      tags: "crude; bizarre",
    },
    {
      authentique: false,
      title: "A Collection of Musings from the Under-5s",
      tags: "ridiculous",
      notes: "xy",
    },
    {
      authentique: false,
      title: "Catching Chlamydia from Koalas",
      tags: "ridiculous",
    },
    {
      authentique: false,
      title:
        "Grooming in the Catholic Church: Charting the Evolution of the Clerical Beard ",
      tags: "pun",
    },
    {
      authentique: false,
      title: "Immaculate Conception and the Vietnamese Dong",
      tags: "ridiculous; bizarre",
    },
    {
      authentique: false,
      title:
        "Mary and Jane, Shelley and Austen: Cannabis and Early 19th-Century English Literature",
      tags: "pun",
    },
    {
      authentique: false,
      title:
        "Non-Newtonian Fluid Displacement in the Estimation of Arachnid Biomass",
      tags: "ridiculous",
      notes: "x",
    },
    {
      authentique: false,
      title: "Observations on Post-Feminist Food Blogs",
      tags: "ridiculous",
      notes: "y",
    },
    {
      authentique: false,
      title: "Sketching my gull colony",
      tags: "bizarre",
    },
    {
      authentique: false,
      title:
        "Invasive or Conversational: Comparing methodologies of insect collection",
    },
    {
      authentique: false,
      title: "The Application of Marxist Theory to Veganism, Karl and his Daal",
      tags: "pun; ridiculous",
      notes: "yz",
    },
    {
      authentique: false,
      title:
        "The Sieve, the Octopus and the Patriarchy - A Shocking Trigonomicon",
      tags: "ridiculous; bizarre",
    },
    {
      authentique: false,
      title:
        "Who Was He? Who Was I? Watching the Paint Dry: Replying to the Replies",
      tags: "bizarre",
    },
    {
      authentique: false,
      title: "Scent and Sensibility: Responsible Use of the Nose",
      tags: "pun; ridiculous",
      notes: "x",
    },
    {
      authentique: false,
      title: "Why talk to the monkey when the organ grinder is in the room?",
      tags: "ridiculous",
    },
    {
      authentique: true,
      title: "Ants & the Art of War ",
      link: "https://www.jstor.org/stable/26002920",
      tags: "ridiculous",
    },
    {
      authentique: true,
      title: "Applying Spaghetti and Meatballs to Proximity Analysis",
      link: "https://www.jstor.org/stable/26926900",
      tags: "ridiculous",
    },
    {
      authentique: true,
      title: "Getting to the Bottom of Anal Evolution",
      tags: "pun",
    },
    {
      authentique: true,
      title:
        "Head and neck injury risks in heavy metal: head bangers stuck between rock and a hard bass.",
      link: "https://www.jstor.org/stable/20511625",
      tags: "pun; ridiculous",
    },
    {
      authentique: true,
      title: "Mother of all Lodes",
      link: "https://www.jstor.org/stable/24917140",
      tags: "ridiculous; crude",
      notes: "z",
    },
    {
      authentique: true,
      title: "Shit White People Say About Beyoncé",
      link: "https://www.jstor.org/stable/10.5325/soundings.97.3.0385",
      tags: "outrageous",
      notes: "z",
    },
    {
      authentique: true,
      title: "Side Boob and Insensibility",
      link: "https://www.jstor.org/stable/43959067",
      tags: "crude; ridiculous",
    },
    {
      authentique: true,
      title: "The Jewish Self Hatred Octopus",
      link: "https://www.jstor.org/stable/25653588",
      tags: "outrageous; bizarre",
    },
    {
      authentique: true,
      title:
        "What Happened to Sex Trafficking? The New Moral Panic of Men, Boys and Fish in the Mekong Region",
      link: "https://www.jstor.org/stable/26696417",
      tags: "bizarre",
    },
    {
      authentique: true,
      title: "You're a Mile Away and You Have Their Shoes",
      link: "https://www.jstor.org/stable/24727095",
      tags: "bizarre",
    },
  ],
};

const vol_v = {
  title: "Pipage and Genghis Khan",
  articles: [
    {
      authentique: false,
      title: "By ‘eck: Rising Alcohol Consumption in Bishkek",
      notes: "y",
    },
    {
      authentique: false,
      title: "Ellipsis, the ultimate Freudian fantasy?",
      notes: "x",
    },
    {
      authentique: false,
      title:
        'Paine, Burke and the French Revolution: a phenomenological reimagining of the linguistic legacy of pamphleteering in the context of early Victorian "knife and fork" radicalism from below',
      tags: "ridiculous",
      notes: "xy",
    },
    {
      authentique: false,
      title:
        "Cuckoldry in Rural Denmark: Findings From a Large Randomized Trial",
      tags: "ridiculous",
    },
    {
      authentique: false,
      title: "How to Piss Off a Feminist",
      tags: "crude",
    },
    {
      authentique: false,
      title: "Roundtable Discussion: Prostration and Thailand's triple threat",
      tags: "ridiculous",
    },
    {
      authentique: false,
      title:
        "The Fallacy of Sexual Ripeness and the 2001 Eritrea-Djibouti Child Crisis",
    },
    {
      authentique: false,
      title:
        "The Kimberly-Jane & Priscilla Soltero Pageant: A Tale of Two Fitties",
      tags: "pun",
    },
    {
      authentique: false,
      title: "Visualizing Breaststroke in 1950s Chinese Film",
      tags: "outrageous",
      notes: "xy",
    },
    {
      authentique: true,
      title:
        "Does This Law Make My Butt Look Big? Part II No, But Food Does: An Overview of the FDA's Menu Labeling Requirements",
      link: "https://www.jstor.org/stable/26421594",
      tags: "ridiculous",
    },
    {
      authentique: true,
      title: "Hung jury: testimonies of genital surgery by transsexual men ",
      tags: "pun",
    },
    {
      authentique: true,
      title: "Musing: A Black Feminist Philosopher: Is That Possible?",
      link: "https://www.jstor.org/stable/24541961",
      tags: "outrageous",
      notes: "z",
    },
    {
      authentique: true,
      title:
        "Nerds, Normal People, and Homeboys: Accommodation and Resistance among Chinese American Students",
      link: "https://www.jstor.org/stable/3196223",
      tags: "ridiculous",
    },
    {
      authentique: true,
      title: "Notes on “Daddy”, 1973",
      tags: "crude",
      notes: "x",
    },
    {
      authentique: true,
      title:
        "Nuclear Power Plants for the Renaissance: Looking into the Crystal Ball",
      link: "https://www.jstor.org/stable/43734989",
      tags: "outrageous; bizarre",
    },
    {
      authentique: true,
      title:
        "Obscene Telephone Calls to Children: A Retrospective Field Study.",
      link: "https://pubmed.ncbi.nlm.nih.gov/11126639/",
      tags: "outrageous",
    },
    {
      authentique: true,
      title: "Playing the Cock",
      link: "https://www.jstor.org/stable/41345654",
      tags: "crude",
    },
    {
      authentique: true,
      title:
        "Poo wars as matter out of place: 'Toilets for Africa' in Cape Town",
      link: "https://www.jstor.org/stable/24030461",
      tags: "crude",
    },
    {
      authentique: true,
      title: "Review: Wet Dreams ",
      tags: "crude",
      notes: "x",
    },
    {
      authentique: true,
      title: "Seasonal Variation in Growth Rates of Normal and Blind Children ",
      link: "https://www.jstor.org/stable/41462323",
      tags: "bizarre; ridiculous",
      notes: "z",
    },
    {
      authentique: true,
      title:
        "Finding Necrophilia in Meat Eating: Mary Daly's Evolving Fem-Veg Perspective",
      link: "https://www.jstor.org/stable/10.2979/jfemistudreli.28.2.93?searchText=necrophilia",
      tags: "bizarre",
      notes: "x",
    },
    {
      authentique: true,
      title: "The Unrivaled Happiness of Otters ",
      link: "https://www.jstor.org/stable/44072238",
      tags: "ridiculous",
    },
    {
      authentique: false,
      title: "What's the Story Morning Tory?",
      tags: "pun",
      notes: "z",
    },
    {
      authentique: false,
      title:
        "Maize and the Not So A-maize-ing Archaeo-Ecological Impacts of Intercropping Systems",
      tags: "ridiculous; pun",
    },
    {
      authentique: false,
      title:
        "Fumbling in the dark: A long-term study into naturally occuring braille",
      tags: "ridiculous",
    },
  ],
};

const vol_vi = {
  title: "The Otter's Pocket",
  articles: [
    {
      authentique: false,
      title:
        "Knocking on Heaven’s Door – Jehovah’s Witnesses and the Proliferation of the Cross",
    },
    {
      authentique: false,
      title: "The Great Charter, Magna Carta, a Firestarter?",
    },
    {
      authentique: false,
      title: "A Brief History of Thyme",
    },
    {
      authentique: false,
      title:
        "Chaos–order transition in the foraging behavior of blind children",
    },
    {
      authentique: false,
      title: "Will the Messiah help stem the rise in childhood obesity? ",
    },
    {
      authentique: false,
      title: "Is anything wetter than an otter's pocket?",
    },
    {
      authentique: false,
      title:
        "“What does the fox say!”: Magnitudinal Variance in Vulpine Coital Whimpers",
    },
    {
      authentique: false,
      title:
        "Snap, Crackle, Pop: Infantile onomatopoeiac dependence and breakfast cereal",
    },
    {
      authentique: false,
      title: "Surnames in Suriname",
    },
    {
      authentique: false,
      title: "The Efficacy of Braille for Street Gang Recruitment",
    },
    {
      authentique: false,
      title:
        "Cuckoldry in Rural Denmark: Findings From a Large Randomized Trial",
    },
    {
      authentique: true,
      title: "On Not Using the Word “Cunt” in a Poem",
    },
    {
      authentique: true,
      title: "A Socio-Spatial Biography of Newcastle Brown Ale",
    },
    {
      authentique: true,
      title:
        "Fantastic yeasts and where to find them: the hidden diversity of dimorphic fungal pathogens",
    },
    {
      authentique: true,
      title: "“Incarcerated in a Large Cheese”",
    },
    {
      authentique: true,
      title: "Kinky Empiricism",
    },
    {
      authentique: true,
      title: "Monastic Masturbation in Pali Buddhist Texts",
    },
    {
      authentique: true,
      title: "Pluralize the Anthropocene!",
    },
    {
      authentique: true,
      title:
        "Transactional Sex, Condom and Lubricant use among Men who have Sex with Men in Lagos State, Nigeria",
    },
    {
      authentique: true,
      title: "Horizontal Learning in Medieval Italian Canonries ",
    },
    {
      authentique: true,
      title:
        "Finding Pornography on the Family Computer: Divorce Case Strategies & Criminal Law Implications",
    },
    {
      authentique: true,
      title:
        "Let Willy Free Himself: The Case For Expanding Standing to Marine Mammals to Challenge Regulations of the Public Display Industry",
    },
    {
      authentique: true,
      title: "Smegma as a carcinogen",
    },
    {
      authentique: true,
      title: "Death in the Toothbrush",
    },
    {
      authentique: false,
      title: "Louis Farrakhan and the Donner Kebab: A Zionist Nightmare?",
    },
  ],
};

const vol_vii = {
  title: "An Ode to Broomrape",
  articles: [
    {
      authentique: false,
      title: "Breaking the glass ceiling: The sudden rise of indoor netball",
    },
    {
      authentique: false,
      title: "A Supine Approach to Ghost Detection",
    },
    {
      authentique: false,
      title:
        "Toucan Play At That Game: The Crimson-Rumped Toucanet and Guava Theft",
    },
    {
      authentique: false,
      title:
        "Use of Noninvasive Parameters to Evaluate the Pain Threshold of Nubian Dromedary Camels",
    },
    {
      authentique: false,
      title: "The Thai Prostitute Phallacy",
    },
    {
      authentique: false,
      title: "Categorising Freud's psychoanalytic files, a Sisyphean folly?",
    },
    {
      authentique: false,
      title: "An Analysis of the Girth of Female Condoms in Malawi",
    },
    {
      authentique: false,
      title: "How reliable are mortality rates as weather forecasts?",
    },
    {
      authentique: false,
      title: "New Orleans, Algebra, an Untapped Potential?",
    },
    {
      authentique: false,
      title: "Vinyl Violence in Mönchengladbach",
    },
    {
      authentique: false,
      title: "rearing geese, bonding with my niece",
    },
    {
      authentique: false,
      title: "The Darkside of the Moon: Struggles with Compulsive Nudity",
    },
    {
      authentique: true,
      title: "Genderfuck: The Law of the Dildo",
    },
    {
      authentique: true,
      title: "Pete, Repete, and One Bagel",
    },
    {
      authentique: true,
      title:
        "Factors Affecting Egyptian Broomrape (Orobanche aegyptiaca) Control in Carrot",
    },
    {
      authentique: true,
      title: "Practice Makes Perfect: Rectal Foreign Bodies.",
    },
    {
      authentique: true,
      title:
        "Bees visiting unopened flowers: bumbling burglars or sneaky pollinators?",
    },
    {
      authentique: true,
      title: "Ass You Lick It: Bey and Jay Eat Cake",
    },
    {
      authentique: true,
      title: "Bollywood/Africa: A Divorce?",
    },
    {
      authentique: true,
      title: "Cock soup",
    },
    {
      authentique: true,
      title: "Children and mini-magnets: an almost fatal attraction ",
    },
    {
      authentique: true,
      title:
        "Prophylactic, Anti-Paedophile Hymn-Writing in Colonial India: An Introduction to Amy Carmichael (1867–1951) and her Missionary Writings ",
    },
    {
      authentique: false,
      title:
        "The Explosion of West African Gastronomy in the 1990s: A Plantain Supernova",
    },
    {
      authentique: true,
      title: "Sibling Incest, Madness, and the ‘Jews'",
    },
    {
      authentique: false,
      title: "Prepubertal orphans as agents of chaos",
    },
  ],
};

const vol_viii = {
  title: "The Phoenix Project",
  articles: [
    {
      authentique: false,
      title:
        "Authenticity in Taiwanese Cell Phone Sex: Selected Letters from Tsai Ing-wen",
    },
    {
      authentique: false,
      title:
        "Coat hanger extortion, the harsh reality of the laissez-faire state",
    },
    {
      authentique: false,
      title: "Sociological Obstacles to Locust Tracking ",
    },
    {
      authentique: false,
      title: "9/11 in the context of karmic reincarnation",
    },
    {
      authentique: false,
      title: "Primates, the Steam Engine, Infinity",
    },
    {
      authentique: false,
      title: "Using Alcohol to Teach Blind Children to Read ",
    },
    {
      authentique: false,
      title:
        'Paine, Burke and the French Revolution, a phenomenological reimagining of the linguistic legacy of pamphleteering in the context of early Victorian "knife and fork" radicalism from below',
    },
    {
      authentique: false,
      title: "A constructivist approach to calisthenics",
    },
    {
      authentique: false,
      title:
        "How to Seduce Your Daughter: Oedipal Etiquette in the Former Yugoslavia",
    },
    {
      authentique: false,
      title:
        "Slap and Tackle: Engineered Homosexuality in Aberdeen's Fisheries ",
    },
    {
      authentique: false,
      title: "The Phoenix Project: The Reincarnation of Arizona Ice Tea",
    },
    {
      authentique: false,
      title: "How do we get rid of orphans?",
    },
    {
      authentique: true,
      title:
        "Application of an Improved Ant Colony Algorithm in Coastal Tourism Route Optimization ",
    },
    {
      authentique: true,
      title: "Jane Austen and the Masturbating Girl",
    },
    {
      authentique: true,
      title: "God's Army: Wiggle Room for the Mormon Soul",
    },
    {
      authentique: true,
      title: "The Fetish and McGwire’s Balls",
    },
    {
      authentique: true,
      title:
        "Miners' magic: artisanal mining, the albino fetish and murder in Tanzania",
    },
    {
      authentique: true,
      title: "Penis Blues",
    },
    {
      authentique: true,
      title:
        "Mortal combat: an apparent intraspecific killing by a male Black-capped Chickadee ",
    },
    {
      authentique: true,
      title:
        "From Chow Chop Suey to Dishes in Cans: How Pennsylvania Chinese American Restaurateurs Lost to Racism, Ruffians, Reformers, and Economic Decline ",
    },
    {
      authentique: true,
      title: "God’s Second Arsehole: A Sonnet",
    },
    {
      authentique: false,
      title: "Knot a Bad Idea: The Use of Topology in String Theory",
    },
    {
      authentique: true,
      title:
        "Material Prayers and Maternity in Early Modern Italy: Signed, Sealed, Delivered ",
    },
    {
      authentique: true,
      title: "Herpes Viral Hepatitis in a Toucan ",
    },
    {
      authentique: true,
      title:
        'Anal Anarchy: A Reading of Mo Yan\'s "The Plagues of Red Locusts" ',
    },
  ],
};

const vol_ix = { title: "coming soon", articles: [] };

export const volumes = [
  vol_i,
  vol_ii,
  vol_iii,
  vol_iv,
  vol_v,
  vol_vi,
  vol_vii,
  vol_viii,
  vol_ix,
].map((v, i) => {
  const articles = i ? shuffle(v.articles) : v.articles;
  return { ...v, articles };
});
